import React, { useState, useEffect, useCallback } from 'react';
import './History.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HistoryItem from '../../layout/HistoryItem';
import GoogleAd from '../../layout/GoogleAd';
import {
    loadHistory,
    deleteHistoryItem,
    clearHistory,
    clearCurrentWord,
    HistoryInterface
} from '../../storage';
import { getAd } from '../../config';

const initialHistory = loadHistory();
const HISTORY_INTERVAL = 3000;

export default function HistoryPage() {
    const [history, setHistory] = useState<HistoryInterface[]>(initialHistory);

    useEffect(() => {
        const timer = setInterval(() => {
            console.log('fetching history');
            const hist = loadHistory();
            setHistory(hist);
        }, HISTORY_INTERVAL)
        return () => clearInterval(timer);
    }, [setHistory]);

    const onDeleteHistoryItem = useCallback((item: HistoryInterface) => {
        setHistory((hist) => {
            const index = hist.findIndex((h) => h.datetime === item.datetime);
            deleteHistoryItem(index);
            const newItems = loadHistory();
            return newItems;
        })
    }, [setHistory]);

    const onDeleteHistory = useCallback(() => {
        setHistory((hist) => {
            clearHistory();
            clearCurrentWord();
            return [];
        })
    }, [setHistory]);

    const historyPageAd = getAd('historyPage');

    return (
        <Box className="page">
            <div className="history-menu">
                <Typography variant="h4">History</Typography>
                {history.length ? (
                    <Button variant="text" onClick={onDeleteHistory}>Clear All History</Button>
                ) : null}
            </div>
            {historyPageAd ? (
                <GoogleAd
                    classNames={['ad-historyPage']}
                    slot={historyPageAd.slot}
                    googleAdId={historyPageAd.adID}
                />
            ) : null}

            {history.length ? history.toReversed().map((h) => (
                <HistoryItem item={h} onDeleteItem={onDeleteHistoryItem} />
            )) : null}
        </Box>
    );
}