import { DefaultSettings } from "./settings";

export const FAVORITES_KEY = 'favorites';
export const HIDDEN_KEY = 'hidden';
export const HISTORY_KEY = 'history';
export const SETTINGS_KEY = 'settings';
export const CURRENT_WORD_KEY = 'currentWord';

export interface FavoritesInterface {
    name: string;
    favorite: boolean;
};

export interface HiddenInterface {
    name: string;
    hidden: boolean;
};

export interface HistoryInterface {
    text: string;
    datetime: Date;
}

export interface SettingsInterface {
    historyLength: number;
    initialText: string;
}

const HISTORY_OVERWRITE_DELTA = 1000 * 60; // one minute

function deltaCheck(newItem: HistoryInterface, oldItem: HistoryInterface): boolean {
    const newDate = new Date(newItem.datetime);
    const oldDate = new Date(oldItem.datetime);
    return Math.abs(newDate.getTime() - oldDate.getTime()) < HISTORY_OVERWRITE_DELTA;
}

function loadData<T>(storage: Storage, key: string, def: T, merge = false): T {
    let data: T = def;
    try {
        const json = storage.getItem(key);
        if (json) {
            if (merge) {
                const d = JSON.parse(json);
                data = {
                    ...data,
                    ...d,
                };
            } else {
                data = JSON.parse(json);
            }
        }
    } catch (err) {
        //
    }
    return data;
}

function saveData<T>(storage: Storage, key: string, data: T) {
    return storage.setItem(key, JSON.stringify(data));
}

// favorites
export function loadFavorites(): FavoritesInterface[] {
    console.log('load favorites');
    return loadData<FavoritesInterface[]>(localStorage, FAVORITES_KEY, []);
}

export function saveFavorites(data: FavoritesInterface[]) {
    console.log('save favorites');
    return saveData<FavoritesInterface[]>(localStorage, FAVORITES_KEY, data);
}

export function clearFavorites() {
    console.log('clear favorites');
    saveFavorites([]);
}


// hidden
export function loadHidden(): HiddenInterface[] {
    console.log('load hidden');
    return loadData<HiddenInterface[]>(localStorage, HIDDEN_KEY, []);
}

export function saveHidden(data: HiddenInterface[]) {
    console.log('save hidden');
    return saveData<HiddenInterface[]>(localStorage, HIDDEN_KEY, data);
}

// history 
export function loadHistory(): HistoryInterface[] {
    console.log('load history');
    return loadData<HistoryInterface[]>(localStorage, HISTORY_KEY, []);
}

export function saveHistory(data: HistoryInterface[]) {
    console.log('save history');
    const settings = loadSettings();
    while (data.length > settings.historyLength) {
        data.shift();
    }
    return saveData<HistoryInterface[]>(localStorage, HISTORY_KEY, data);
}

// TODO get settings and check saved history length
export function appendHistoryItem(item: HistoryInterface) {
    console.log('append history');
    const settings = loadSettings();
    const history = loadHistory();
    if (!history.length) {
        history.push(item);
    } else {
        const lastItem = history[history.length - 1];
        if (lastItem.text !== item.text) {
            // overwrite if last is subset of new and within last $HISTORY_OVERWRITE_DELTA miliseconds
            if ((item.text.includes(lastItem.text) || lastItem.text.includes(item.text)) && deltaCheck(item, lastItem)) {
                history[history.length - 1] = item;
            } else if (item.text) {
                history.push(item);
            }
        }
    }
    while (history.length > settings.historyLength) {
        history.shift();
    }
    saveHistory(history);
}

export function deleteHistoryItem(index: number) {
    console.log('remove history');
    const history = loadHistory();
    if (index >= 0 && index < history.length) {
        history.splice(index, 1);
        saveHistory(history);
    }
}

export function clearHistory() {
    console.log('clear history');
    saveHistory([]);
}

// settings 
export function loadSettings(): SettingsInterface {
    console.log('load settings');
    return loadData<SettingsInterface>(localStorage, SETTINGS_KEY, DefaultSettings, true);
}

export function saveSettings(data: SettingsInterface) {
    console.log('save settings');
    return saveData<SettingsInterface>(localStorage, SETTINGS_KEY, data);
}

// current word 
export function loadCurrentWord(): string {
    console.log('load current word');
    return loadData<string>(sessionStorage, CURRENT_WORD_KEY, '');
}

export function saveCurrentWord(data: string) {
    console.log('save current word:', data);
    return saveData<string>(sessionStorage, CURRENT_WORD_KEY, data);
}

export function clearCurrentWord() {
    console.log('clear current word');
    return saveData<string>(sessionStorage, CURRENT_WORD_KEY, '');
}

// purge
export function purgeStorage() {
    localStorage.clear();
    sessionStorage.clear();
}