import React from 'react';
import Home from '../pages/Home';
import History from '../pages/History';
import Saved from '../pages/Saved';
import Settings from '../pages/Settings';

const routes: { name: string, path: string, element: React.ReactNode }[] = [
    {
        name: 'Write',
        path: '/',
        element: <Home />,
    },
    {
        name: 'History',
        path: '/history',
        element: <History />,
    },
    {
        name: 'Saved',
        path: '/saved',
        element: <Saved />,
    },
    {
        name: 'Settings',
        path: '/settings',
        element: <Settings />,
    }
]

export default routes;