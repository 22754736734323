import React from 'react';
import './InputForm.css';

import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';


type Props = {
    text: string;
    onInputChange: (text: string) => void;
};

export default function InputForm(props: Props) {
    const { text, onInputChange } = props;

    return (
        <Box
            className="input-field"
            component="form"
            noValidate
            autoCapitalize="none"
            autoCorrect="off"
            autoComplete="off"
        >
            <TextField
                id="input_field"
                className="input-text-field"
                label=""
                type="email"
                value={text}
                placeholder="Your text here"
                variant="outlined"
                autoCapitalize="none"
                autoCorrect="off"
                autoComplete="off"
                fullWidth
                multiline
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onInputChange(event.target.value);
                }} />
            <IconButton className="input-clear" disabled={!text.length} onClick={() => {
                onInputChange('');
            }}>
                <ClearIcon />
            </IconButton>
        </Box>
    )
}

