import React, { useState, useCallback } from 'react';
import './Saved.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SavedItem from '../../layout/SavedItem';
import GoogleAd from '../../layout/GoogleAd';
import characterMaps, { UnicodeCharacterMap } from '../../translators/charsets';
import {
    loadFavorites,
    saveFavorites,
    loadHidden,
    saveHidden,
} from '../../storage';
import { getAd } from '../../config';

const TAB_ALL = 0;
const TAB_FAVORITES = 1;
const TAB_HIDDEN = 2;

const initialFavorites = loadFavorites();
const initialHidden = loadHidden();
let initialCharMaps: UnicodeCharacterMap[] = characterMaps;
if (initialFavorites.length || initialHidden.length) {
    initialCharMaps = initialCharMaps.reduce((acc, cm) => {
        const fav = initialFavorites.find((f) => f.name === cm.name);
        const hid = initialHidden.find((f) => f.name === cm.name);
        acc.push({
            ...cm,
            favorite: fav?.favorite ?? cm.favorite,
            hidden: hid?.hidden ?? cm.hidden,
        })
        return acc;
    }, [] as UnicodeCharacterMap[]);
}

export default function SavedPage() {
    const [tab, setTab] = useState<number>(0)
    const [charmaps, setCharmaps] = useState<UnicodeCharacterMap[]>(initialCharMaps);

    const onTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    }, [setTab]);

    const onFavoriteChange = useCallback((cmap: UnicodeCharacterMap) => {
        setCharmaps((cmaps) => {
            const index = cmaps.findIndex((cm) => cm.name === cmap.name)
            cmaps[index].favorite = !cmaps[index].favorite;
            saveFavorites(cmaps.map((cm) => ({
                name: cm.name,
                favorite: !!cm.favorite,
            })));
            return [...cmaps];
        });
    }, [setCharmaps]);

    const onHiddenChange = useCallback((cmap: UnicodeCharacterMap) => {
        setCharmaps((cmaps) => {
            const index = cmaps.findIndex((cm) => cm.name === cmap.name)
            cmaps[index].hidden = !cmaps[index].hidden;
            saveHidden(cmaps.map((cm) => ({
                name: cm.name,
                hidden: !!cm.hidden,
            })));
            return [...cmaps];
        });
    }, [setCharmaps]);

    const onResetAll = useCallback(() => {
        setCharmaps((cmaps) => {
            for (let i = 0; i < cmaps.length; i++) {
                cmaps[i].favorite = false;
                cmaps[i].hidden = false;
            }
            saveHidden([]);
            saveFavorites([]);
            return [...cmaps];
        });
    }, [setCharmaps]);

    const onClearFavorites = useCallback(() => {
        setCharmaps((cmaps) => {
            for (let i = 0; i < cmaps.length; i++) {
                cmaps[i].favorite = false;
            }
            saveFavorites([]);
            return [...cmaps];
        });
    }, [setCharmaps]);

    const onClearHidden = useCallback(() => {
        setCharmaps((cmaps) => {
            for (let i = 0; i < cmaps.length; i++) {
                cmaps[i].hidden = false;
            }
            saveHidden([]);
            return [...cmaps];
        });
    }, [setCharmaps]);

    const noop = (charset: UnicodeCharacterMap) => { };

    const favorites = charmaps.filter((f) => !!f.favorite);
    const hidden = charmaps.filter((h) => !!h.hidden);

    const favoritePageAd = getAd('favoritePage');

    return (
        <Box className="page">
            <div className="saved-menu">
                <Typography variant="h4">Saved</Typography>
            </div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={onTabChange} aria-label="basic tabs example">
                    <Tab label="All Charsets" id="saved-tab-all" aria-controls="saved-tabpanel-all" />
                    <Tab label="Favorites" id="saved-tab-favorites" aria-controls="saved-tabpanel-favorites" />
                    <Tab label="Hidden" id="saved-tab-hidden" aria-controls="saved-tabpanel-hidden" />
                </Tabs>
            </Box>
            <div
                role="tabpanel"
                hidden={tab !== TAB_ALL}
                id="saved-tabpanel-all"
                aria-labelledby="saved-tab-all"
            >
                {tab === TAB_ALL && (
                    <Box sx={{ pt: 2 }}>
                        {favorites.length || hidden.length ? (
                            <div className="saved-submenu">
                                <Button onClick={onResetAll}>Reset All</Button>
                            </div>
                        ) : null}
                        {favoritePageAd ? (
                            <GoogleAd
                                classNames={['ad-favoritePage']}
                                slot={favoritePageAd.slot}
                                googleAdId={favoritePageAd.adID}
                            />
                        ) : null}
                        {charmaps.map((c) => (
                            <SavedItem
                                item={c}
                                showFavorite={true}
                                showHidden={true}
                                onFavoriteChange={onFavoriteChange}
                                onHiddenChange={onHiddenChange} />
                        ))}
                    </Box>
                )}
            </div>
            <div
                role="tabpanel"
                hidden={tab !== TAB_FAVORITES}
                id="saved-tabpanel-favorites"
                aria-labelledby="saved-tab-favorites"
            >
                {tab === TAB_FAVORITES && (
                    <Box sx={{ pt: 2 }}>
                        {favorites.length ? (
                            <div className="saved-submenu">
                                <Button onClick={onClearFavorites}>Clear Favorites</Button>
                            </div>
                        ) : null}
                        {favorites.map((f) => (
                            <SavedItem
                                item={f}
                                showFavorite={true}
                                showHidden={false}
                                onFavoriteChange={onFavoriteChange}
                                onHiddenChange={noop} />
                        ))}
                    </Box>
                )}
            </div>
            <div
                role="tabpanel"
                hidden={tab !== TAB_HIDDEN}
                id="saved-tabpanel-hidden"
                aria-labelledby="saved-tab-hidden"
            >
                {tab === TAB_HIDDEN && (
                    <Box sx={{ pt: 2 }}>
                        {hidden.length ? (
                            <div className="saved-submenu">
                                <Button onClick={onClearHidden}>Clear Hidden</Button>
                            </div>
                        ) : null}
                        {hidden.map((h) => (
                            <SavedItem
                                item={h}
                                showFavorite={false}
                                showHidden={true}
                                onFavoriteChange={noop}
                                onHiddenChange={onHiddenChange} />
                        ))}
                    </Box>
                )}
            </div>

        </Box>
    )
}