export const MAX_HISTORY = 500;

export const DefaultSettings = {
    initialText: '',
    historyLength: 50,
}

export const SettingsType: Record<string, 'string' | 'number'> = {
    initialText: 'string',
    historyLength: 'number',
}

export const SettingsFormatter: Record<string, Function> = {
    historyLength: (n: any): number => {
        let num = parseInt(`${n}`, 10);
        if (Number.isNaN(num)) {
            num = DefaultSettings.historyLength as number;
        } else {
            num = Math.max(Math.min(num, MAX_HISTORY), 0);
        }
        return num;
    },
    initialText: (v: string): string => {
        return `${v}`.trim();
    }
}

export function settingNameFormatter(key: string): string {
    return key.split('').reduce((acc, l) => {
        if (l.toUpperCase() === l) {
            acc.push(' ');
        }
        acc.push(acc.length === 0 ? l.toUpperCase() : l);
        return acc;
    }, [] as string[]).join('');
}