import React, { useCallback } from 'react';
import './SavedItem.css';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { UnicodeCharacterMap } from '../../translators/charsets';
import { transmogrify } from '../../translators/translators';


type Props = {
    item: UnicodeCharacterMap;
    showFavorite: boolean;
    showHidden: boolean;
    onFavoriteChange: (item: UnicodeCharacterMap) => void;
    onHiddenChange: (item: UnicodeCharacterMap) => void;
};

const sampleText = 'abcdefghijklmnopqrstuvwxyz';
const sampleNumbers = '0123456789';

export default function SavedItem(props: Props) {
    const { item, showFavorite, showHidden, onFavoriteChange, onHiddenChange } = props;

    const onChangeFavorite = useCallback(() => {
        onFavoriteChange(item);
    }, [item, onFavoriteChange])

    const onChangeHidden = useCallback(() => {
        onHiddenChange(item);
    }, [item, onHiddenChange])

    return (
        <div className="saved-item">
            <div className="saved-content">
                <span>{item.name}</span>
                <p>{transmogrify(sampleText.toUpperCase(), item)}</p>
                <p>{transmogrify(sampleText.toLowerCase(), item)}</p>
                {item.numericStartPoint ? (
                    <p>{transmogrify(sampleNumbers, item)}</p>
                ) : null}
            </div>
            {showFavorite ? (
                <IconButton onClick={onChangeFavorite}>
                    {item.favorite ? (<StarIcon />) : (<StarBorderIcon />)}
                </IconButton>
            ) : null}
            {showHidden ? (
                <IconButton onClick={onChangeHidden}>
                    {item.hidden ? (<VisibilityOffIcon />) : (<VisibilityIcon />)}
                </IconButton>
            ) : null}

        </div>
    )
}