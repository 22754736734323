export interface Ad {
    format?: string;
    slot: string;
    adID: string;
}

export type AdKey = 'homeTop' | 'homeBottom' | 'settingsPage' | 'historyPage' | 'favoritePage';

export interface Config {
    showAds: boolean;
    ads: Record<AdKey, Ad | null>;
}

export const HIDE_PARAM = 'g';

const NO_ADS = 'NO_ADS';

export function shouldHideAds(str: string, param: string): boolean {
    const stored = window.localStorage.getItem(NO_ADS);
    if (stored === '1') {
        return true;
    } else if (str && str.length > 1) {
        return param in getParams(str);
    }
    return false;
}

export function setHideAds() {
    window.localStorage.setItem(NO_ADS, '1');
}

export function getParams(str: string): Record<string, string> {
    const p: Record<string, string> = {}
    if (str && str.length > 1) {
        str.slice(1).split('&').forEach((v) => {
            const [key, value] = v.split('=');
            p[key || '_'] = value ?? '';
        })
        return p
    }
    return p;
}


const AD_ID = 'ca-pub-3034525312040343';

const config: Config = {
    showAds: true,
    ads: {
        homeTop: {
            slot: '9399696841',
            adID: AD_ID,
        },
        homeBottom: {
            slot: '4179993909',
            adID: AD_ID,
        },
        settingsPage: {
            slot: '3002960833',
            adID: AD_ID,
        },
        historyPage: {
            slot: '9376797499',
            adID: AD_ID,
        },
        favoritePage: {
            slot: '1920979305',
            adID: AD_ID,
        },
    }
};

export default config;

export function getAd(key: AdKey): Ad | null {
    const noAds = shouldHideAds(window?.location?.search, HIDE_PARAM);
    if (config.showAds && !noAds) {
        return config.ads[key];
    }
    return null;
}