import React, { useState, useCallback } from 'react';
import './Settings.css';
import { DefaultSettings, SettingsType } from '../../settings';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SettingsItem from '../../layout/SettingsItem';
import GoogleAd from '../../layout/GoogleAd';
import {
    loadSettings,
    saveSettings,
    purgeStorage,
    loadFavorites,
    loadHidden,
    loadHistory,
    SettingsInterface,
    FavoritesInterface,
    HiddenInterface,
    HistoryInterface,
} from '../../storage';
import { getAd, setHideAds, shouldHideAds, HIDE_PARAM } from '../../config';

const HIDE_COUNT = 5;
let timer: NodeJS.Timeout | null = null;

export default function SettingsPage() {
    const [settings, setSettings] = useState<SettingsInterface>(loadSettings());
    const [favorites, setFavorites] = useState<FavoritesInterface[]>(loadFavorites());
    const [hidden, setHidden] = useState<HiddenInterface[]>(loadHidden());
    const [history, setHistory] = useState<HistoryInterface[]>(loadHistory());
    const [taps, setTaps] = useState<number>(shouldHideAds(window.location.search, HIDE_PARAM) ? HIDE_COUNT : 0);

    const onResetDefaults = useCallback(() => {
        setSettings(DefaultSettings);
        saveSettings(DefaultSettings);
    }, [setSettings]);

    const onPugeData = useCallback(() => {
        purgeStorage();
        setFavorites([]);
        setHidden([]);
        setHistory([]);
        setSettings(DefaultSettings);
        saveSettings(DefaultSettings);
        setTimeout(() => {
            setTaps(shouldHideAds(window.location.search, HIDE_PARAM) ? HIDE_COUNT : 0);
        }, 200);
    }, [setFavorites, setHidden, setHistory, setTaps]);

    const onSettingChange = useCallback((key: string, value: number | string) => {
        const newSettings = {
            ...settings,
            [key]: value,
        };
        setSettings(newSettings);
        saveSettings(newSettings);
    }, [settings, setSettings]);

    const settingsPageAd = getAd('settingsPage');

    const onClickName = useCallback(() => {
        setTaps((t) => {
            if (timer) {
                clearTimeout(timer);
            }
            const nt = t + 1;
            if (nt >= HIDE_COUNT) {
                setHideAds();
            } else {
                timer = setTimeout(() => {
                    setTaps(shouldHideAds(window.location.search, HIDE_PARAM) ? HIDE_COUNT : 0);
                }, 1000);
            }
            return nt;
        })
    }, [taps, setTaps]);

    return (
        <Box className="page">
            <div className="settings-menu">
                <Typography variant="h4" onClick={onClickName}>
                    Settings
                    {taps >= 5 ? (<small>∅</small>) : ''}
                </Typography>
                <div className="settings-controls">
                    <Button onClick={onResetDefaults}>Reset Defaults</Button>
                    <Button onClick={onPugeData}>Purge Data</Button>
                </div>
            </div>
            {settingsPageAd ? (
                <GoogleAd
                    classNames={['ad-settingsPage']}
                    slot={settingsPageAd.slot}
                    googleAdId={settingsPageAd.adID}
                />
            ) : null}
            <div className="settings-info">
                <Typography variant="subtitle1">Stats</Typography>
                <p>Favorite Charsets: {favorites.length}</p>
                <p>Hidden Charsets: {hidden.length}</p>
                <p>History Entries: {history.length}</p>
            </div>
            <div className="settings-entries">
                {Object.entries(settings).map(([sKey, sValue]) => (
                    <SettingsItem
                        name={sKey}
                        value={sValue}
                        type={SettingsType[sKey]}
                        onChange={onSettingChange} />
                ))}
            </div>
            <p className="settings-note">Add data stored locally in the browser's <code>localStorage</code></p>
        </Box>
    );
}