import React, { useCallback } from 'react';
import './HistoryItem.css';
import { HistoryInterface } from '../../storage';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


type Props = {
    item: HistoryInterface;
    onDeleteItem: (item: HistoryInterface) => void;
};

export default function HistoryItem(props: Props) {
    const { item, onDeleteItem } = props;
    const onDelete = useCallback(() => {
        onDeleteItem(item);
    }, [item, onDeleteItem])
    return (
        <div className="history-item">
            <div className="history-content">
                <p>{item.text}</p>
                <span>{item.datetime.toLocaleString()}</span>
            </div>
            <IconButton onClick={onDelete}>
                <DeleteIcon />
            </IconButton>
        </div>
    )
}