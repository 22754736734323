import React, { useState, useCallback } from 'react';
import './Home.css';
import characterMaps, { UnicodeCharacterMap, sortCharacterMaps } from '../../translators/charsets';
import Box from '@mui/material/Box';
import InputForm from '../../layout/InputForm';
import Translation from '../../layout/Translation';
import GoogleAd from '../../layout/GoogleAd';
import {
    loadFavorites,
    saveFavorites,
    loadHidden,
    saveHidden,
    appendHistoryItem,
    loadCurrentWord,
    saveCurrentWord,
    loadSettings,
} from '../../storage';
import { getAd } from '../../config';

const DEBOUNCE_TIME = 500;
let timer: NodeJS.Timeout | null = null;

const initialFavorites = loadFavorites();
const initialHidden = loadHidden();
const initialSettings = loadSettings();
let initialCharMaps: UnicodeCharacterMap[] = characterMaps;
if (initialFavorites.length || initialHidden.length) {
    initialCharMaps = initialCharMaps.reduce((acc, cm) => {
        const fav = initialFavorites.find((f) => f.name === cm.name);
        const hid = initialHidden.find((f) => f.name === cm.name);
        acc.push({
            ...cm,
            favorite: fav?.favorite ?? cm.favorite,
            hidden: hid?.hidden ?? cm.hidden,
        })
        return acc;
    }, [] as UnicodeCharacterMap[]);
}

export default function HomePage() {
    const [text, setText] = useState<string>(loadCurrentWord() || initialSettings.initialText || '');
    const [charmaps, setCharmaps] = useState<UnicodeCharacterMap[]>(initialCharMaps);

    const onInputChange = useCallback((input: string) => {
        setText(input);
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            saveCurrentWord(input);
            appendHistoryItem({
                text: input,
                datetime: new Date(),
            });
        }, DEBOUNCE_TIME);
    }, [setText]);

    const toggleCharMapFavorite = useCallback((cmap: UnicodeCharacterMap) => {
        setCharmaps((cmaps) => {
            const index = cmaps.findIndex((cm) => cm.name === cmap.name)
            cmaps[index].favorite = !cmaps[index].favorite;
            saveFavorites(cmaps.map((cm) => ({
                name: cm.name,
                favorite: !!cm.favorite,
            })));
            return [...cmaps];
        });
    }, [setCharmaps]);

    const toggleCharMapHidden = useCallback((cmap: UnicodeCharacterMap) => {
        setCharmaps((cmaps) => {
            const index = cmaps.findIndex((cm) => cm.name === cmap.name)
            cmaps[index].hidden = !cmaps[index].hidden;
            saveHidden(cmaps.map((cm) => ({
                name: cm.name,
                hidden: !!cm.hidden,
            })));
            return [...cmaps];
        });
    }, [setCharmaps]);

    const homeTopAd = getAd('homeTop');
    const homeBottomAd = getAd('homeBottom');

    return (
        <Box className="page">
            <InputForm text={text} onInputChange={onInputChange} />
            {homeTopAd ? (
                <GoogleAd
                    classNames={['ad-hometop']}
                    slot={homeTopAd.slot}
                    googleAdId={homeTopAd.adID}
                />
            ) : null}
            {text.length ? sortCharacterMaps(charmaps).map((charmap) => (
                <Translation
                    key={charmap.name}
                    text={text}
                    charmap={charmap}
                    toggleCharMapFavorite={toggleCharMapFavorite}
                    toggleCharMapHidden={toggleCharMapHidden} />
            )) : null}
            {homeBottomAd ? (
                <GoogleAd
                    classNames={['ad-homebottom']}
                    slot={homeBottomAd.slot}
                    googleAdId={homeBottomAd.adID}
                />
            ) : null}
        </Box>
    )
}