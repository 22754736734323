import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import urls from './routes/routes';
import Root from './routes/Root';
import Error from './routes/Error';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <Error />,
        children: urls.map((url) => ({
            path: url.path,
            element: url.element,
        })),
    },
]);

const theme = createTheme({
    palette: {
        primary: {
            light: '#092',
            main: '#092',
            dark: '#092',
            contrastText: '#000',
        },
        secondary: {
            light: '#f20',
            main: '#f20',
            dark: '#f20',
            contrastText: '#fff',
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
            <CssBaseline />
        </ThemeProvider>
    );
}

export default App;
