import React, { useEffect } from 'react';
import './GoogleAd.css';


type Props = {
    slot: string;
    googleAdId: string;
    format?: string;
    classNames?: string[];
    style?: Record<string, string>,
};

export default function GoogleAd(props: Props) {
    const { classNames, slot, googleAdId, style, format } = props;
    const cls = [
        'ad-block',
        ...(classNames ? classNames : []),
    ].join(' ');

    useEffect(() => {
        setTimeout(() => {
            if (typeof window !== 'undefined') {
                // eslint-disable 
                window.adsbygoogle = window.adsbygoogle || []
                window.adsbygoogle.push({})
                // eslint-enable 
            }
        }, 200)
    });

    return (
        <div className={cls} >
            <ins
                className="adsbygoogle"
                style={style || { display: 'block', textAlign: "center" }}
                data-ad-client={googleAdId}
                data-ad-slot={slot}
                data-ad-format={format || "auto"}
                data-full-width-responsive="true"
            ></ins>
        </div >
    )
}