import { UnicodeCharacterMap } from "./charsets";

const ZWS = String.fromCodePoint(8203);

const UPPER_START = 'A'.charCodeAt(0);
const UPPER_END = 'Z'.charCodeAt(0);
const LOWER_START = 'a'.charCodeAt(0);
const LOWER_END = 'z'.charCodeAt(0);
const NUM_START = '0'.charCodeAt(0);
const NUM_END = '9'.charCodeAt(0);

export function isUpperCase(code: number): boolean {
    return code >= UPPER_START && code <= UPPER_END;
}

export function isLowerCase(code: number): boolean {
    return code >= LOWER_START && code <= LOWER_END;
}

export function isNumber(code: number): boolean {
    return code >= NUM_START && code <= NUM_END;
}

interface Offset {
    offset: number;
    type: 'upper' | 'lower' | 'number' | 'none';
}

export function getOffset(letter: string): Offset {
    const code = letter.charCodeAt(0);
    if (isUpperCase(code)) {
        return {
            type: 'upper',
            offset: code - UPPER_START,
        };
    }
    if (isLowerCase(code)) {
        return {
            type: 'lower',
            offset: code - LOWER_START,
        };
    }
    if (isNumber(code)) {
        return {
            type: 'number',
            offset: code - NUM_START,
        };
    }
    return {
        type: 'none',
        offset: -1,
    };
}


export function transmogrify(text: string, charset: UnicodeCharacterMap): string {
    const result: string[] = [];
    if (text && text.length) {
        const overrides = charset.overrides || {};
        text.split('').forEach((letter) => {
            const override = overrides[letter];
            if (override) {
                result.push(String.fromCodePoint(override));
            } else {
                let newLetter = letter;
                const offset = getOffset(letter);

                switch (offset.type) {
                    case 'upper':
                        newLetter = String.fromCodePoint(charset.upperStartPoint + offset.offset);
                        break;
                    case 'lower':
                        newLetter = String.fromCodePoint(charset.lowerStartPoint + offset.offset);
                        break;
                    case 'number':
                        if (charset.numericStartPoint) {
                            newLetter = String.fromCodePoint(charset.numericStartPoint + offset.offset);
                        }
                        break;
                    default:
                        break;
                }
                result.push(newLetter);
                if (newLetter !== letter && charset.addSpaces) {
                    result.push(ZWS);
                }
            }
        });
    }
    return result.join('');
}