export interface UnicodeCharacterMap {
    name: string;
    upperStartPoint: number;
    lowerStartPoint: number;
    numericStartPoint?: number;
    overrides?: Record<string, number>;
    addSpaces?: boolean;
    favorite?: boolean;
    hidden?: boolean;
};

export function sortCharacterMaps(cmaps: UnicodeCharacterMap[]): UnicodeCharacterMap[] {
    const favs: UnicodeCharacterMap[] = [];
    const hidden: UnicodeCharacterMap[] = [];
    const rest: UnicodeCharacterMap[] = [];
    const results: UnicodeCharacterMap[] = [];
    cmaps.forEach((cm) => {
        if (cm.favorite && !cm.hidden) {
            favs.push(cm);
        } else if (cm.hidden) {
            hidden.push(cm)
        } else {
            rest.push(cm);
        }
    });
    return results.concat(favs).concat(rest).concat(hidden);
}

const characterMaps: UnicodeCharacterMap[] = [
    // Math Serif
    {
        name: 'Mathematical Serif Bold',
        upperStartPoint: 119808,
        lowerStartPoint: 119834,
        numericStartPoint: 120782,
    },
    {
        name: 'Mathematical Serif Italic',
        upperStartPoint: 119860,
        lowerStartPoint: 119886,
        overrides: {
            h: 8462,
        }
    },
    {
        name: 'Mathematical Serif Bold Italic',
        upperStartPoint: 119912,
        lowerStartPoint: 119938,
    },
    // Math Sans-Serif
    {
        name: 'Mathematical Sans-Serif Normal',
        upperStartPoint: 120224,
        lowerStartPoint: 120250,
        numericStartPoint: 120802,
    },
    {
        name: 'Mathematical Sans-Serif Bold',
        upperStartPoint: 120276,
        lowerStartPoint: 120302,
        numericStartPoint: 120812,
    },
    {
        name: 'Mathematical Sans-Serif Italic',
        upperStartPoint: 120328,
        lowerStartPoint: 120354,
    },
    {
        name: 'Mathematical Sans-Serif Bold Italic',
        upperStartPoint: 120380,
        lowerStartPoint: 120406,
    },
    // Math Script
    {
        name: 'Mathematical Script Normal',
        upperStartPoint: 119964,
        lowerStartPoint: 119990,
        overrides: {
            e: 8495,
            g: 8458,
            o: 8500,
            B: 8492,
            E: 8496,
            F: 8497,
            H: 8459,
            I: 8464,
            L: 8466,
            M: 8499,
            R: 8475,
        }
    },
    {
        name: 'Mathematical Script Bold',
        upperStartPoint: 120016,
        lowerStartPoint: 120042,
    },
    // Math Fraktur
    {
        name: 'Mathematical Fraktur Normal',
        upperStartPoint: 120068,
        lowerStartPoint: 120094,
        overrides: {
            C: 8493,
            H: 8460,
            I: 8465,
            R: 8476,
            Z: 8488,
        }
    },
    {
        name: 'Mathematical Fraktur Bold',
        upperStartPoint: 120172,
        lowerStartPoint: 120198,
    },
    // Math Monospace
    {
        name: 'Mathematical Monospace',
        upperStartPoint: 120432,
        lowerStartPoint: 120458,
        numericStartPoint: 120822,
    },
    // Math Doublestruck
    {
        name: 'Mathematical Doublestruck',
        upperStartPoint: 120120,
        lowerStartPoint: 120146,
        numericStartPoint: 120792,
        overrides: {
            C: 8450,
            H: 8461,
            N: 8469,
            P: 8473,
            Q: 8474,
            R: 8477,
            Z: 8484,
        }
    },
    // Squared Latin Capital
    {
        name: 'Squared Latin Capital',
        upperStartPoint: 127280,
        lowerStartPoint: 127280,
    },
    // Negative Squared Latin Capital
    {
        name: 'Negative Squared Latin Capital',
        upperStartPoint: 127344,
        lowerStartPoint: 127344,
    },
    // Circled Latin Capital
    {
        name: 'Circled Latin Capital',
        upperStartPoint: 9398,
        lowerStartPoint: 9424,
        numericStartPoint: 9311,
        overrides: {
            0: 9450,
        }
    },
    // Negative Circled Latin Capital
    {
        name: 'Negative Circled Latin Capital',
        upperStartPoint: 127312,
        lowerStartPoint: 127312,
    },
    // Regional Indicator
    {
        name: 'Regional Indicator',
        upperStartPoint: 127462,
        lowerStartPoint: 127462,
        addSpaces: true,
    },
    // FullWidth Latin
    {
        name: 'FullWidth Latin',
        upperStartPoint: 65313,
        lowerStartPoint: 65345,
        numericStartPoint: 65296,
    },
];

export default characterMaps.sort((a, b) => {
    if (a.name < b.name) {
        return -1;
    } else {
        return 1;
    }
});