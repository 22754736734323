import React, { useCallback, useState } from 'react';
import './Translation.css';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { UnicodeCharacterMap } from '../../translators/charsets';
import { transmogrify } from '../../translators/translators';


type Props = {
    text: string;
    charmap: UnicodeCharacterMap;
    toggleCharMapFavorite: (cm: UnicodeCharacterMap) => void
    toggleCharMapHidden: (cm: UnicodeCharacterMap) => void
};

export default function Translation(props: Props) {
    const { text, charmap, toggleCharMapFavorite, toggleCharMapHidden } = props;
    const [copied, setCopied] = useState(false);

    const onFavoriteClick = useCallback(() => {
        toggleCharMapFavorite(charmap);
    }, [charmap, toggleCharMapFavorite]);

    const onHiddenClick = useCallback(() => {
        toggleCharMapHidden(charmap);
    }, [charmap, toggleCharMapHidden]);

    const translated = transmogrify(text.trim(), charmap);

    return (
        <div className="translation"
            data-favorite={charmap.favorite ? 'true' : 'false'}
            data-hidden={charmap.hidden ? 'true' : 'false'}
            data-copied={copied ? 'true' : 'false'}>
            <span className="translation-copied">Copied Text</span>
            <div className="translation-content">
                <p>{charmap.name}</p>
                <span className="translation-output">{translated}</span>
            </div>
            {window.navigator.clipboard ? (
                <IconButton className="translation-copy-btn" onClick={() => {
                    console.log(translated);
                    navigator.clipboard.writeText(translated).then(() => {
                        setCopied(true);
                        setTimeout(() => {
                            setCopied(false);
                        }, 2000);
                    });
                }} title="Copy Text">
                    <ContentCopyIcon />
                </IconButton>
            ) : null}
            <IconButton className="translation-favorite-btn" onClick={onFavoriteClick} title="Add font to favorites">
                {charmap.favorite ? (<StarIcon />) : (<StarBorderIcon />)}
            </IconButton>
            <IconButton className="translation-hidden-btn" onClick={onHiddenClick} title="Hide font">
                {charmap.hidden ? (<VisibilityOffIcon />) : (<VisibilityIcon />)}
            </IconButton>
        </div>
    )
}

