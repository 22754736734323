import { Outlet } from "react-router-dom";
import Navigation from '../layout/Navigation';

export default function Root() {
    return (<div className="main page-inner">
        <Navigation></Navigation>
        <main>
            <Outlet />
            <footer>&copy; 2024 <a target="_blank" href="https://www.grgrssll.com">grgrssll</a></footer>
        </main>
    </div >)
}