import React, { useCallback } from 'react';
import './SettingsItem.css';
import { MAX_HISTORY, SettingsFormatter, settingNameFormatter } from '../../settings';

type Props = {
    name: string;
    value: number;
    type: 'number' | 'string';
    onChange: (name: string, value: number | string) => void,
};

export default function SettingsItem(props: Props) {
    const { name, value, type, onChange } = props;

    const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let val = type === 'number' ? parseInt(event.target.value, 10) : event.target.value;
        if (typeof SettingsFormatter[name] === 'function') {
            val = SettingsFormatter[name](val);
        }
        onChange(name, val);
    }, [name, type, onChange]);

    const id = `settings_input_${name.toLowerCase().replace(/[^0-9a-z]/g, '')}`;
    return (
        <div className="settings-number">
            <label htmlFor={id}>{settingNameFormatter(name)}:</label>
            {type === 'number' ? (
                <input id={id} type="number" value={value} min="0" max={MAX_HISTORY} onChange={onInputChange} />
            ) : (
                <input id={id} type="text" value={value} onChange={onInputChange} />
            )}
        </div>
    );
}